<template>
    <div class="lay_container">
        <div class="container_body" >
            <div class="guidng"> <Header></Header></div>
            <div class="cont_m">
                <router-view :key="this.refreshKey ? this.refreshKey : this.$route.fullPath" />
            </div>
        </div>

        <div class="foot_fixed " v-if="!isphone">
            <Footer></Footer>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Header from '@/components/header';
import Footer from '@/components/footer';
export default {
    components: { Header, Footer },
    data() {
        return {
            isphone:true
        };
    },

    computed: {
        ...mapGetters(['refreshKey']),
    },
      created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 768) {
        // 手机端逻辑
        this.isphone=true
      } else {
        // 非手机端逻辑
        this.isphone=false
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }
};
</script>
<style scoped>
.cont_m {
    margin-top: 170px;
    margin-bottom: 80px;
}
.guidng{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index:1000;
}

</style>
